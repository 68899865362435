import { Box, Card, Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import FeatureSlider from "../components/FeatureSlider";
import BestPlace from "../components/BestPlace";
import HomePageTop from "../components/HomePageTop";
import Footer from "../components/Footer";
import BannerSlider from "../components/BannerSlider";
import Advantage from "../components/Advantage";
import OurPartners from "../components/OurPartners";
import AOS from "aos";
import HappyMember from "../components/HappyMembers";
import HappyCustomers from "../components/HappyCustomers";
import InsuranceCard from "../components/InsuranceCard";

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 900,
    });
  }, []);
  return (
    <>
      <Container sx={{ marginTop: "20px" }}>
        <HomePageTop />
        <InsuranceCard />
        <Box className="more-products">
          <Typography variant="body1">ALSO BUY</Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
          <Card className="card" sx={{ padding: "8px", borderRadius: "8px" }}>
            <Link to="">
              <span className="term">Investment</span>
              LIC Plans
            </Link>
          </Card>
          <Card className="card" sx={{ padding: "8px", borderRadius: "8px" }}>
            <Link to="">
              <span className="term">Term Life</span>
              Return of Premium
            </Link>
          </Card>
          <Card className="card" sx={{ padding: "8px", borderRadius: "8px" }}>
            <Link to="">
              <span className="term">Investment</span>
              LIC Plans
            </Link>
          </Card>
          <Card className="card" sx={{ padding: "8px", borderRadius: "8px" }}>
            <Link to="">
              <span className="term">Term Life</span>
              Return of Premium
            </Link>
          </Card>
          <Card className="card" sx={{ padding: "8px", borderRadius: "8px" }}>
            <Link to="">
              <span className="term">Investment</span>
              LIC Plans
            </Link>
          </Card>
          <Card className="card" sx={{ padding: "8px", borderRadius: "8px" }}>
            <Link to="">
              <span className="term">Term Life</span>
              Return of Premium
            </Link>
          </Card>
          <Card className="card" sx={{ padding: "8px", borderRadius: "8px" }}>
            <Link to="">
              <span className="term">Investment</span>
              LIC Plans
            </Link>
          </Card>
        </Box>
        <Box sx={{ display: "flex", gap: "20px", marginTop: "20px", flexWrap: "wrap" }}>
          <Card className="card" sx={{ padding: "8px", borderRadius: "8px" }}>
            <Link to="">
              <span className="term">Investment</span>
              LIC Plans
            </Link>
          </Card>
          <Card className="card" sx={{ padding: "8px", borderRadius: "8px" }}>
            <Link to="">
              <span className="term">Term Life</span>
              Return of Premium
            </Link>
          </Card>
          <Card className="card" sx={{ padding: "8px", borderRadius: "8px" }}>
            <Link to="">
              <span className="term">Investment</span>
              LIC Plans
            </Link>
          </Card>
          <Card className="card" sx={{ padding: "8px", borderRadius: "8px" }}>
            <Link to="">
              <span className="term">Term Life</span>
              Return of Premium
            </Link>
          </Card>

          <Card className="card" sx={{ padding: "8px", borderRadius: "8px" }}>
            <Link to="">
              <span className="term">Term Life</span>
              Return of Premium
            </Link>
          </Card>
        </Box>
      </Container>
      <Box sx={{ backgroundColor: "#F2F7FF", padding: "30px", marginTop: "20px" }}>
        <Container>
          <HappyCustomers />

        </Container>
      </Box>
      <BestPlace />
      <Box sx={{ backgroundColor: "#F2F7FF", padding: "30px", marginTop: "20px" }}>
        <Container>
          <BannerSlider />
        </Container>
      </Box>
      <Container>
        <Advantage />
      </Container>

      <Box sx={{ backgroundColor: "#F2F7FF", padding: "30px", marginTop: "20px" }}>
        <Container>

          <FeatureSlider />
        </Container>
      </Box>
      <Container>
        <HappyMember />
      </Container>
      <Box sx={{ backgroundColor: "#F2F7FF", padding: "30px", marginTop: "20px" }}>
        <Container>
          <OurPartners />
        </Container>
      </Box>
    </>
  );
};

export default Home;
