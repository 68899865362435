import { Box, Button, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import advanceimg from "../Images/advance-img.jpg";
import bestimg from "../Images/best-img.jpeg";
import bestimg2 from "../Images/bestimg-2.jpeg";
import AOS from "aos";

const Advantage = () => {
  useEffect(() => {
    AOS.init({
      duration: 900,
    });
  }, []);

  return (
    <Box pt={5} pb={5} data-aos="fade-down">
      <Typography mb={2} variant="h4">
        PB Advantage
      </Typography>
      <span className="bottom-line"></span>
      <Typography className="advance-text" mb={2} pt={3} variant="body1">
        When you buy insurance from us, you get more than just financial safety. You also get: our promise of simplifying complex insurance terms and conditions, quick stress-free claims, instant
        quotes from top insurers and being present for you in the toughest of times.
      </Typography>
      <Button variant="text">Know more</Button>
      <Box
        data-aos="fade-up"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          marginTop: "40px",
          gap: "62px",
        }}
      >
        <Paper className="advance-card" sx={{ p: 4 }} variant="outlined">
          <img src={advanceimg} alt="" />
          <Typography pt={2} sx={{ lineHeight: '20px' }} variant="h6">One of the best Prices</Typography>
          <Typography variant="subtitle1">Guaranteed</Typography>
        </Paper>
        <Paper className="advance-card" sx={{ p: 4 }} variant="outlined">
          <img src={bestimg} alt="" />
          <Typography pt={2} sx={{ lineHeight: '20px' }} variant="h6">Unbiased Advice</Typography>
          <Typography variant="subtitle1">Keeping customers first</Typography>
        </Paper>
        <Paper className="advance-card" sx={{ p: 4 }} variant="outlined">
          <img src={advanceimg} alt="" />
          <Typography pt={2} sx={{ lineHeight: '20px' }} variant="h6">100% Reliable</Typography>
          <Typography variant="subtitle1">Regulated by IRDAI</Typography>
        </Paper>
        <Paper className="advance-card" sx={{ p: 4 }} variant="outlined">
          <img src={bestimg2} alt="" />
          <Typography pt={2} sx={{ lineHeight: '20px' }} variant="h6">Claims Support</Typography>
          <Typography variant="subtitle1">Made stress-free</Typography>
        </Paper>
      </Box>
    </Box>
  );
};

export default Advantage;
