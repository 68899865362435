import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/material";
const BannerSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
    ],
  };
  return (
    <Box className="slider-img">
      <Slider {...settings}>
        <Box>
          <img alt="slider" src="https://static.pbcdn.in/cdn/images/home-v1/banner-24x7.png" />
        </Box>
        <Box>
          <img alt="slider" src="https://static.pbcdn.in/cdn/images/home-v1/banner-24x7.png" />
        </Box>
        <Box>
          <img alt="slider" src="https://static.pbcdn.in/cdn/images/home-v1/banner-24x7.png" />
        </Box>
        <Box>
          <img alt="slider" src="https://static.pbcdn.in/cdn/images/home-v1/banner-24x7.png" />
        </Box>
        <Box>
          <img alt="slider" src="https://static.pbcdn.in/cdn/images/home-v1/banner-24x7.png" />
        </Box>
      </Slider>
    </Box>
  );
};

export default BannerSlider;
