import { Box, Button, Card, CardActions, CardContent, Container, Grid, Typography } from "@mui/material";
import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StarIcon from "@mui/icons-material/Star";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
const InsurancePlan = () => {
  return (
    <Container sx={{ marginTop: "90px" }}>
      <Grid container spacing={2}>
        <Grid item md={8}>
          <Card sx={{ borderRadius: "10px", marginBottom: "20px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 1,
                m: 1,
                bgcolor: "background.paper",
                borderRadius: 1,
              }}
            >
              <Box>Item 1</Box>
              <Box>Item 1</Box>
              <Box>Item 1</Box>
            </Box>
          </Card>
        </Grid>
        <Grid item md={4}>
          <Card sx={{ borderRadius: "10px" }}></Card>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item md={8}>
          <Card sx={{ borderRadius: "10px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 1,
                m: 1,
              }}
            >
              <Box>
                <img
                  alt="rating"
                  src="https://static.pbcdn.in/term-cdn/images/images/insurer/Aegon_Life_logo.png"
                  style={{
                    height: 40,
                    width: 100,
                  }}
                />
                <br />
                <Typography variant="p" style={{ fontSize: "11px", lineHeight: "0", color: "rgb(38, 57, 89, 0.7)" }}>iTerm Prime</Typography>
              </Box>
              <Box className="Claim">
                <Typography variant="p" component='p'>
                  Claim settled <br />
                  <a href="/">
                    <b> 99.4% </b>
                  </a>
                </Typography>
              </Box>
              <Typography variant="p" className="Claim">
                <Typography>
                  Coverage till <br />
                  <b> 50 Yrs </b>
                  <br />
                  <small>(Max Limit: 50 yrs)</small>
                </Typography>
              </Typography>
              <Box className="Claim">
                <Typography variant="p" component="p">
                  Life cover <br />
                  <b className="red-color">₹49.99 Lac</b>
                </Typography>
              </Box>
              <Button className="btn-month btn-shine" size="small" variant="contained" endIcon={<ArrowForwardIosIcon fontSize="small" />}>
                <b>694</b>/<small className="month">month</small>
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                p: 1,
                m: 1,
              }}
            >
              <Typography variant="p" component="p" className="Issuance-Chance align-item-center">
                <img
                  alt="rating"
                  src="https://static.pbcdn.in/term-cdn/images/images/high_icon.svg"
                  style={{
                    height: 15,
                    width: 15,
                  }}
                />
                Excellent
                <br />
                Issuance Chance
              </Typography>

              <Typography variant="p" component="p" className="Physical align-item-center">
                <AddCircleOutlineIcon fontSize="small" />
                Physical Medical
              </Typography>

              <Typography variant="p" component="p" className="Physical align-item-center">
                <StarIcon color="primary" fontSize="small" />
                Special Plan for <b>Self-Employed</b>
              </Typography>

              <Typography variant="p" component="p" className="more-plan" style={{ marginLeft: "auto" }}>
                +1 More Plan <ExpandMoreIcon fontSize="small" />
              </Typography>
            </Box>
            <hr />
            <Typography sx={{marginBottom:'5px'}} variant="p" component="p" className="year">
              <span>10% online discount included </span>(for 1st year)
            </Typography>
          </Card>
        </Grid>
        <Grid item md={4}>
        <Card sx={{ minWidth: 275 }}>
      <CardContent>
      <Typography gutterBottom variant="h5" component="div">
            India`s Growth Story 
          </Typography>
          <Typography variant="body2" color="text.secondary">
            is just getting started
          </Typography>
      </CardContent>
      <CardActions>
        <Button variant="primary" size="small">Know More</Button>
      </CardActions>
    </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default InsurancePlan;
