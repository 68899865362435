// import logo from './logo.svg';
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import TermLifeInsurance from "./pages/CustomerDetails";
import Home from "./pages/Home";
import InsurancePlan from "./pages/InsurancePlan";
import Login from "./pages/Login";
import Footer from "./components/Footer";
function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/InsurancePlan" element={<InsurancePlan />} />
          <Route path="/TermLifeInsurance" element={<TermLifeInsurance />} />
          <Route path="/login" element={<Login />} />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </>
  );
}

export default App;
