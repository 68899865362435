import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, Grid, Switch, TextField, Typography } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { Link } from "react-router-dom";
import SeletCountry from "../components/SeletCountry";

const CustomerDetails = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Container sx={{ marginTop: "90px" }}>
        <Grid container boxShadow={2} spacing={2} sx={{ borderRadius: "20px", width: "100%" }} margin={0}>
          <Grid md={5} xs={12} sx={{ background: "linear-gradient(180deg, #0065ff 50.2%, #0c44a3 100%)", padding: "25px", borderRadius: "20px" }}>
            <Typography variant="p" component="p" sx={{ textAlign: "center", color: "white", marginTop: "40px" }}>
              <span className="price">₹1 Crore </span>life cover starting <br />
              from <span className="pr-month">₹525</span>
              <em>/month</em>
              <sup>+</sup>
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
              <Typography className="discount" variant="p" component="p" sx={{ textAlign: "center" }}>
                Get an online discount of
                <strong>
                  upto 10%<sup>#</sup>
                </strong>
              </Typography>
            </Box>
            <Box sx={{ background: "#fff", borderRadius: "15px", padding: "10px", marginTop: "40px" }}>
              <Typography variant="p" component="p" sx={{ textAlign: "center" }}>
                We are rated~
                <br />
                <img
                  alt="rating"
                  src="https://static.pbcdn.in/term-cdn/images/images/rating.png"
                  style={{
                    height: 36,
                    width: 215,
                  }}
                />
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  p: 1,
                  m: 1,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                }}
              >
                <Typography className="text-center br-left">
                  <strong>6.7</strong>
                  <small>Crore</small>
                  <br />
                  Policies Sold
                </Typography>
                <Typography className="text-center br-left">
                  <strong>6.7</strong>
                  <small>Crore</small>
                  <br />
                  Policies Sold
                </Typography>
                <Typography className="text-center ">
                  <strong>6.7</strong>
                  <small>Crore</small>
                  <br />
                  Policies Sold
                </Typography>
              </Box>
              <Typography className="text-center">
                Policybazaar is one of<strong> India's leading</strong> digital insurance marketplace
              </Typography>
            </Box>
          </Grid>
          <Grid md={7} xs={12} sx={{ background: "white", padding: "10px", borderRadius: "20px" }}>
            <Box sx={{ margin: "20px 100px 0 100px" }}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                  <FormControlLabel value="male" control={<Radio />} label="Male" />
                  <FormControlLabel value="female" control={<Radio />} label="Female" />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={{ margin: "20px 100px 0 100px" }}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                label="Your Name"
                id="fullWidth"
              />
            </Box>
            <Box sx={{ margin: "20px 100px 0 100px" }}>
              <TextField
                fullWidth
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                label="Date of Birth"
                id="fullWidth"
              />
            </Box>
            <Box sx={{ margin: "20px 100px 0 100px", display: "flex", gap: "5px" }}>
              {/* <TextField
                fullWidth
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                label="Mobile Number"
                id="fullWidth"
              /> */}
              <SeletCountry />
            </Box>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Link to="/insuranceplan">
                <Button sx={{ margin: "20px 100px 0 100px" }} variant="contained" endIcon={<ArrowRightAltIcon />}>
                  View Free Quotes
                </Button>
              </Link>
            </Box>
            <Box style={{ display: "flex", justifyContent: "center",marginTop:'5px' }}>
              <Typography component="p" variant="p" className="certified">Only certified Policybazaar expert will assist you</Typography>
            </Box>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Typography variant="p" style={{ fontSize: "12px", display: "flex", alignItems: "center" }}>
                <WhatsAppIcon fontSize="small" />
                Get updates on WhatsApp <Switch defaultChecked size="small" />
              </Typography>
            </Box>
            <Typography component="p" style={{ textAlign: "center", fontSize: "12px" }}>
              By clicking, you agree to our
              <a target="_blank" href="/">
                Privacy policy,
              </a>
              <a target="_blank" href="/">
                Terms of Use
              </a>
              <span>
                <sup>+</sup> Disclaimers
              </span>
            </Typography>
          </Grid>
        </Grid>
        <Accordion sx={{ marginTop: "20px",boxShadow:'0' }} expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Disclaimers<sup>+</sup>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit amet egestas eros, vitae egestas augue. Duis vel est augue.</Typography>
          </AccordionDetails>
        </Accordion>
      </Container>
    </>
  );
};

export default CustomerDetails;
