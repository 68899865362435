import { Box, IconButton, Paper, Typography } from "@mui/material";
import React, { useRef } from "react";
import Slider from "react-slick";
import dummy from "../Images/dummy-img.png";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

const HappyCustomers = () => {
  const slider = useRef (null)
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    arrows: false,
    dots: true,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <Typography align="center" variant="h4">
        Voice of Happy Customers
      </Typography>
      <Box mb={1} align='right'>
        <IconButton sx={{color:'#138f82'}} onClick={() => slider.current.slickPrev()} aria-label="prev">
        <ArrowBackIosNewOutlinedIcon/>
        </IconButton>
        <IconButton sx={{color:'#138f82'}} onClick={() => slider.current.slickNext()} aria-label="next">
          <ArrowForwardIosOutlinedIcon />
        </IconButton>
      </Box>
      <Slider ref={slider} {...settings}>
        <Box className="customer-slider">
          <Paper className="customer-voice" variant="outlined">
            <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <img src={dummy} alt="" />
              <Typography variant="h5">Rajeev Kumar</Typography>
            </Box>

            <Typography align="justify" pt={2} variant="body2">
              As an NRI, I was concerned about reinstating my policy online as an NRI, but Manohar's exceptional assistance put my worries to rest. His dedicated support ensured smooth formalities,
              reaffirming that 'Tata means Trust'.
            </Typography>
          </Paper>
        </Box>
        <Box className="customer-slider">
          <Paper className="customer-voice" variant="outlined">
            <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <img src={dummy} alt="" />
              <Typography variant="h5">Rajeev Kumar</Typography>
            </Box>
            <Typography align="justify" pt={2} variant="body2">
              As an NRI, I was concerned about reinstating my policy online as an NRI, but Manohar's exceptional assistance put my worries to rest. His dedicated support ensured smooth formalities,
              reaffirming that 'Tata means Trust'.
            </Typography>
          </Paper>
        </Box>
        <Box className="customer-slider">
          <Paper className="customer-voice" variant="outlined">
            <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <img src={dummy} alt="" />
              <Typography variant="h5">Rajeev Kumar</Typography>
            </Box>
            <Typography align="justify" pt={2} variant="body2">
              As an NRI, I was concerned about reinstating my policy online as an NRI, but Manohar's exceptional assistance put my worries to rest. His dedicated support ensured smooth formalities,
              reaffirming that 'Tata means Trust'.
            </Typography>
          </Paper>
        </Box>
        <Box className="customer-slider">
          <Paper className="customer-voice" variant="outlined">
            <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <img src={dummy} alt="" />
              <Typography variant="h5">Rajeev Kumar</Typography>
            </Box>
            <Typography align="justify" pt={2} variant="body2">
              As an NRI, I was concerned about reinstating my policy online as an NRI, but Manohar's exceptional assistance put my worries to rest. His dedicated support ensured smooth formalities,
              reaffirming that 'Tata means Trust'.
            </Typography>
          </Paper>
        </Box>
        <Box className="customer-slider">
          <Paper className="customer-voice" variant="outlined">
            <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <img src={dummy} alt="" />
              <Typography variant="h5">Rajeev Kumar</Typography>
            </Box>
            <Typography align="justify" pt={2} variant="body2">
              As an NRI, I was concerned about reinstating my policy online as an NRI, but Manohar's exceptional assistance put my worries to rest. His dedicated support ensured smooth formalities,
              reaffirming that 'Tata means Trust'.
            </Typography>
          </Paper>
        </Box>
      </Slider>
    </>
  );
};

export default HappyCustomers;
