import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import { Box, Container } from '@mui/material';
import { Link } from 'react-router-dom';

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <AppBar sx={{backgroundColor:'#fff',boxShadow:'0'}} position="static">
      <Container>

      <Toolbar>
        <IconButton
          edge="start"
          color="primary"
          aria-label="menu"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { md: 'none' } }}
          
        >
          <MenuIcon />
        </IconButton>
        <Typography className='logo' variant="h6" component="div" sx={{ flexGrow: 1 }}>
         <img src='https://www.unicodesystems.in/theme/web/core/unicode/images/logo.png' alt=''/>
        </Typography>
        <Drawer
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
        >
          <MenuItem onClick={handleClose}>Insurance</MenuItem>
          <MenuItem onClick={handleClose}>Loans</MenuItem>
          <MenuItem onClick={handleClose}>Investments</MenuItem>
          {/* Add more menu items for the drawer */}
        </Drawer>
        <Button
          color="inherit"
          aria-controls="dropdown-menu"
          aria-haspopup="true"
          onClick={handleClick}
          sx={{ display: { md: 'inline-flex',marginRight:'5px',color:'black' } }}
        >
          More
        </Button>
        <Menu
          id="dropdown-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>Investments</MenuItem>
          <MenuItem onClick={handleClose}>Investments</MenuItem>
          {/* Add more dropdown items here */}
        </Menu>
     
        <Button variant="outlined" size="small">
         <Link to={'login'}> Login</Link>
        </Button>
      </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;