import { Box, Card, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import party from "../Images/party-icon.png";
import search from "../Images/Search.png";
import happy from "../Images/happy.png";
import Claims from "../Images/Claims.png";
import AOS from "aos";

const BestPlace = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <Box className="best-place" sx={{ padding: "35px 0" }}>
      <Container>
        <Grid container spacing={2}>
          <Grid sx={{ alignSelf: "center" }} item xs={12} md={7}>
            <Box data-aos="fade-right" className="best-place-text">
              <Typography variant="h4">
                What makes
                <br />
                <span>Policybazaar </span> one of
                <br /> <span>India's favourite places</span>
                <br />
                to
                <span> buy insurance?</span>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box sx={{ display: "flex", gap: "40px" }}>
              <Card data-aos="flip-right" className="card" sx={{ padding: "20px" }}>
                <img className="best-place-img" src={party} alt=""/>
                <Typography className="best-place-heading best-place-1" marginTop="5px" component="div" variant="5">
                  Over 9 million
                </Typography>
                <Typography sx={{ mt: 2 }} variant="body2">
                  customers trust us & have bought their insurance on Policybazaar
                </Typography>
              </Card>
              <Card data-aos="flip-left" className="card" sx={{ padding: "20px" }}>
              <img className="best-place-img" src={search} alt=""/>
                <Typography className="best-place-heading best-place-2"  marginTop="5px" component="div"  variant="5">
                  50+ insurers
                </Typography>
                <Typography sx={{ mt: 2 }} variant="body2">
                  customers trust us & have bought their insurance on Policybazaar
                </Typography>
              </Card>
            </Box>
            <Box sx={{ display: "flex", gap: "40px", marginTop: "40px" }}>
              <Card data-aos="flip-right" className="card" sx={{ padding: "20px" }}>
              <img className="best-place-img" src={happy} alt=""/>
                <Typography className="best-place-heading best-place-2" marginTop="5px" component="div" variant="5">
                  Great Price
                </Typography>
                <Typography sx={{ mt: 2 }} variant="body2">
                  customers trust us & have bought their insurance on Policybazaar
                </Typography>
              </Card>
              <Card data-aos="flip-left" className="card" sx={{ padding: "20px" }}>
              <img className="best-place-img" src={Claims} alt=""/>
                <Typography className="best-place-heading best-place-1" marginTop="5px" component="div" variant="5">
                  Claims
                </Typography>
                <Typography sx={{ mt: 2 }} variant="body2">
                  customers trust us & have bought their insurance on Policybazaar
                </Typography>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default BestPlace;
