import React from 'react';
import { AppBar, Toolbar, Typography, Container, Link, Box, Grid } from '@mui/material';

const Footer = () => {
  return (
    <>
    <Grid
      container
      component="footer"
      sx={{
        bgcolor: 'black',
        color: 'white',
        py: 3,
      }}
    >
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item xs={6} sm={3}>
            
            <Typography variant="body2" align="center">
            <Typography variant='h6'>Life Insurance</Typography>
              <Link to="" color="inherit" underline="hover">
                Column 1 link
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography variant="body2" align="center">
            <Typography variant='h6'>Life Insurance</Typography>
              <Link to="" color="inherit" underline="hover">
                Column 1 link
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography variant="body2" align="center">
            <Typography variant='h6'>Life Insurance</Typography>
              <Link to="" color="inherit" underline="hover">
                Column 1 link
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography variant="body2" align="center">
            <Typography variant='h6'>Life Insurance</Typography>
              <Link to="" color="inherit" underline="hover">
                Column 1 link
              </Link>
            </Typography>
          </Grid>
         
        </Grid>
      </Grid>
    </Grid>

<AppBar position="static" color="primary">
<Container>
  <Toolbar>
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="body1" color="inherit">
        Your Company Name &copy; {new Date().getFullYear()}
      </Typography>
    </Box>
    <Box>
      <Link href="#" color="inherit" sx={{ marginRight: 2 }}>
        Privacy Policy
      </Link>
      <Link href="#" color="inherit">
        Terms of Service
      </Link>
    </Box>
  </Toolbar>
</Container>
</AppBar>
</>
  );
};

export default Footer;
