import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import icicilogo from "../Images/icici-logo.png"
const OurPartners = () => {
  return (
    <>
      <Typography align="center" variant="h4">
        Our Partners
        <Typography variant="body1">Leading insurers for your financial freedom</Typography>
      </Typography>
      <Box pt={5} pb={5} sx={{display:'flex',justifyContent:'space-between',flexWrap:'wrap',gap:'12px'}}>
        <Paper variant="outlined" className="our-partners">
            <img src={icicilogo} alt="" title="ICICI Prudential Life Insurance"/>
        </Paper>
        <Paper variant="outlined" className="our-partners">
            <img src={icicilogo} alt="" title="ICICI Prudential Life Insurance"/>
        </Paper>
        <Paper variant="outlined" className="our-partners">
            <img src={icicilogo} alt="" title="ICICI Prudential Life Insurance"/>
        </Paper>
        <Paper variant="outlined" className="our-partners">
            <img src={icicilogo} alt="" title="ICICI Prudential Life Insurance"/>
        </Paper>
        <Paper variant="outlined" className="our-partners">
            <img src={icicilogo} alt="" title="ICICI Prudential Life Insurance"/>
        </Paper>
        <Paper variant="outlined" className="our-partners">
            <img src={icicilogo} alt="" title="ICICI Prudential Life Insurance"/>
        </Paper>
        <Paper variant="outlined" className="our-partners">
            <img src={icicilogo} alt="" title="ICICI Prudential Life Insurance"/>
        </Paper>
        <Paper variant="outlined" className="our-partners">
            <img src={icicilogo} alt="" title="ICICI Prudential Life Insurance"/>
        </Paper>
        <Paper variant="outlined" className="our-partners">
            <img src={icicilogo} alt="" title="ICICI Prudential Life Insurance"/>
        </Paper>
        <Paper variant="outlined" className="our-partners">
            <img src={icicilogo} alt="" title="ICICI Prudential Life Insurance"/>
        </Paper>
        <Paper variant="outlined" className="our-partners">
            <img src={icicilogo} alt="" title="ICICI Prudential Life Insurance"/>
        </Paper>
        <Paper variant="outlined" className="our-partners">
            <img src={icicilogo} alt="" title="ICICI Prudential Life Insurance"/>
        </Paper>
        <Paper variant="outlined" className="our-partners">
            <img src={icicilogo} alt="" title="ICICI Prudential Life Insurance"/>
        </Paper>
        <Paper variant="outlined" className="our-partners">
            <img src={icicilogo} alt="" title="ICICI Prudential Life Insurance"/>
        </Paper>
      </Box>

    </>
  );
};

export default OurPartners;
