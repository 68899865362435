import { Box, Card, Paper, Typography } from "@mui/material";
import React from "react";
import heart from "../Images/helth-icon.png";
import bike from "../Images/bike.png";
import car from "../Images/car.png";
import lifeinsurance from "../Images/life-insurance.png"
import familyicon from "../Images/family-insurance.png";
import homeinsurance from "../Images/home-insurance.png";
import travelinsurance from "../Images/travel-insurance.png";
import retirement from "../Images/retirement.png";
import child from "../Images/child-plan.png";
import women from "../Images/women.png";
import Guaranteed from "../Images/Guaranteed.png";
import termplan from "../Images/term-plan.png";
import EmployeeGroup from "../Images/Employee-Group.png";
import InvestmentPlans from "../Images/Investment-Plans.png";
import { Link } from "react-router-dom";

const InsuranceCard = () => {
  return (
    <>
      <Box className="insurant-plan" pt={4} pb={2} sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", gap: "5px" }}>
        <Paper className="hover-card" elevation={0} sx={{ display: "flex", p: 1, bgcolor: "inherit", borderRadius: 1 }}>
          <Link to="/TermLifeInsurance">
            <Card sx={{ padding: "15px", background: "#F2F7FF", display: "flex", justifyContent: "center", width: "110px", height: "60px", position: "relative", borderRadius: "8px" }}>
              <img className="card-icon" alt="plan" src={lifeinsurance} />
              <span className="hi-light discount-offer">Upto 30% Discount</span>
            </Card>
            <Typography variant="p" component="p" className="card-title">
              Term Life Insurance
            </Typography>
          </Link>
        </Paper>
        <Paper className="hover-card" elevation={0} sx={{ display: "flex", p: 1, bgcolor: "inherit", borderRadius: 1 }}>
          <Link to="/TermLifeInsurance">
            <Card sx={{ padding: "15px", background: "#F2F7FF", display: "flex", justifyContent: "center", width: "110px", height: "60px", position: "relative", borderRadius: "8px" }}>
              <img className="card-icon" alt="plan" src={heart} />
              <span className="hi-light discount-offer">Upto 25% Discount</span>
            </Card>
            <Typography variant="p" component="p" className="card-title">
              Helth Insurance
            </Typography>
          </Link>
        </Paper>
        <Paper className="hover-card" elevation={0} sx={{ display: "flex", p: 1, bgcolor: "inherit", borderRadius: 1 }}>
          <Link to="/TermLifeInsurance">
            <Card sx={{ padding: "15px", background: "#F2F7FF", display: "flex", justifyContent: "center", width: "110px", height: "60px", position: "relative", borderRadius: "8px" }}>
              <img className="card-icon" alt="plan" src={bike} />
              <span className="hi-light discount-offer">Upto 30% Discount</span>
            </Card>
            <Typography variant="p" component="p" className="card-title">
              2 Wheeler Insurance
            </Typography>
          </Link>
        </Paper>
        <Paper className="hover-card" elevation={0} sx={{ display: "flex", p: 1, bgcolor: "inherit", borderRadius: 1 }}>
          <Link to="/TermLifeInsurance">
            <Card sx={{ padding: "15px", background: "#F2F7FF", display: "flex", justifyContent: "center", width: "110px", height: "60px", position: "relative", borderRadius: "8px" }}>
              <img className="card-icon" alt="plan" src={car} />
              <span className="hi-light discount-offer">Upto 35% Discount</span>
            </Card>
            <Typography variant="p" component="p" className="card-title">
              Car Insurance
            </Typography>
          </Link>
        </Paper>
        <Paper className="hover-card" elevation={0} sx={{ display: "flex", p: 1, bgcolor: "inherit", borderRadius: 1 }}>
          <Link to="/TermLifeInsurance">
            <Card sx={{ padding: "15px", background: "#F2F7FF", display: "flex", justifyContent: "center", width: "110px", height: "60px", position: "relative", borderRadius: "8px" }}>
              <img className="card-icon" alt="plan" src={familyicon} />
              <span className="hi-light discount-offer">Upto 30% Discount</span>
            </Card>
            <Typography variant="p" component="p" className="card-title">
              Family Health Insurance
            </Typography>
          </Link>
        </Paper>
        <Paper className="hover-card" elevation={0} sx={{ display: "flex", p: 1, bgcolor: "inherit", borderRadius: 1 }}>
          <Link to="/TermLifeInsurance">
            <Card sx={{ padding: "15px", background: "#F2F7FF", display: "flex", justifyContent: "center", width: "110px", height: "60px", position: "relative", borderRadius: "8px" }}>
              <img className="card-icon" alt="plan" src={homeinsurance} />
              <span className="hi-light discount-offer">Upto 30% Discount</span>
            </Card>
            <Typography variant="p" component="p" className="card-title">
              Home Insurance
            </Typography>
          </Link>
        </Paper>
        <Paper className="hover-card" elevation={0} sx={{ display: "flex", p: 1, bgcolor: "inherit", borderRadius: 1 }}>
          <Link to="/TermLifeInsurance">
            <Card sx={{ padding: "15px", background: "#F2F7FF", display: "flex", justifyContent: "center", width: "110px", height: "60px", position: "relative", borderRadius: "8px" }}>
              <img className="card-icon" alt="plan" src={travelinsurance} />
              <span className="hi-light discount-offer">Upto 30% Discount</span>
            </Card>
            <Typography variant="p" component="p" className="card-title">
              Travel Insurance
            </Typography>
          </Link>
        </Paper>
        <Paper className="hover-card" elevation={0} sx={{ display: "flex", p: 1, bgcolor: "inherit", borderRadius: 1 }}>
          <Link to="/TermLifeInsurance">
            <Card sx={{ padding: "15px", background: "#F2F7FF", display: "flex", justifyContent: "center", width: "110px", height: "60px", position: "relative", borderRadius: "8px" }}>
              <img className="card-icon" alt="plan" src={women} />
              <span className="hi-light discount-offer">Upto 30% Discount</span>
            </Card>
            <Typography variant="p" component="p" className="card-title">
            Term Insurance <br/> (Women)
            </Typography>
          </Link>
        </Paper>
        <Paper className="hover-card" elevation={0} sx={{ display: "flex", p: 1, bgcolor: "inherit", borderRadius: 1 }}>
          <Link to="/TermLifeInsurance">
            <Card sx={{ padding: "15px", background: "#F2F7FF", display: "flex", justifyContent: "center", width: "110px", height: "60px", position: "relative", borderRadius: "8px" }}>
              <img className="card-icon" alt="plan" src={Guaranteed} />
              <span className="hi-light discount-offer">Upto 30% Discount</span>
            </Card>
            <Typography variant="p" component="p" className="card-title">
            Guaranteed Return <br/>
            Plans
            </Typography>
          </Link>
        </Paper>
        <Paper className="hover-card" elevation={0} sx={{ display: "flex", p: 1, bgcolor: "inherit", borderRadius: 1 }}>
          <Link to="/TermLifeInsurance">
            <Card sx={{ padding: "15px", background: "#F2F7FF", display: "flex", justifyContent: "center", width: "110px", height: "60px", position: "relative", borderRadius: "8px" }}>
              <img className="card-icon" alt="plan" src={child} />
              <span className="hi-light premium">Premium Waiver</span>
            </Card>
            <Typography variant="p" component="p" className="card-title">
              Child Savings plans
            </Typography>
          </Link>
        </Paper>
        <Paper className="hover-card" elevation={0} sx={{ display: "flex", p: 1, bgcolor: "inherit", borderRadius: 1 }}>
          <Link to="/TermLifeInsurance">
            <Card sx={{ padding: "15px", background: "#F2F7FF", display: "flex", justifyContent: "center", width: "110px", height: "60px", position: "relative", borderRadius: "8px" }}>
              <img className="card-icon" alt="plan" src={retirement}  />
              <span className="hi-light discount-offer">Upto 30% Discount</span>
            </Card>
            <Typography variant="p" component="p" className="card-title">
            Retirement Plans
            </Typography>
          </Link>
        </Paper>
        <Paper className="hover-card" elevation={0} sx={{ display: "flex", p: 1, bgcolor: "inherit", borderRadius: 1 }}>
          <Link to="/TermLifeInsurance">
            <Card sx={{ padding: "15px", background: "#F2F7FF", display: "flex", justifyContent: "center", width: "110px", height: "60px", position: "relative", borderRadius: "8px" }}>
              <img className="card-icon" alt="plan" src={termplan} />
              <span className="hi-light discount-offer">Upto 30% Discount</span>
            </Card>
            <Typography variant="p" component="p" className="card-title">
            Free of Cost Term<br/> Plan
            </Typography>
          </Link>
        </Paper>
        <Paper className="hover-card" elevation={0} sx={{ display: "flex", p: 1, bgcolor: "inherit", borderRadius: 1 }}>
          <Link to="/TermLifeInsurance">
            <Card sx={{ padding: "15px", background: "#F2F7FF", display: "flex", justifyContent: "center", width: "110px", height: "60px", position: "relative", borderRadius: "8px" }}>
              <img className="card-icon" alt="plan" src={EmployeeGroup} />
              <span className="hi-light discount-offer">Upto 30% Discount</span>
            </Card>
            <Typography variant="p" component="p" className="card-title">
            Employee Group <br/> Health Insurance
            </Typography>
          </Link>
        </Paper>
        <Paper className="hover-card" elevation={0} sx={{ display: "flex", p: 1, bgcolor: "inherit", borderRadius: 1 }}>
          <Link to="/TermLifeInsurance">
            <Card sx={{ padding: "15px", background: "#F2F7FF", display: "flex", justifyContent: "center", width: "110px", height: "60px", position: "relative", borderRadius: "8px" }}>
              <img className="card-icon" alt="plan" src={InvestmentPlans}/>
              <span className="hi-light discount-offer">Upto 30% Discount</span>
            </Card>
            <Typography variant="p" component="p" className="card-title">
            Investment Plans
            </Typography>
          </Link>
        </Paper>
      </Box>
    </>
  );
};

export default InsuranceCard;
