import { Box, Grid, Typography } from "@mui/material";
import React from "react";

import TopSlider from "../components/TopSlider";
import quickeasy from "../Images/Quick-easy.png";
import bestprice from "../Images/best-price.png";
const HomePageTop = () => {
  return (
    <Box className="bg-color" sx={{ padding: '40px', borderRadius: '10px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <Box className="d-none">
            <Typography variant="p" className="top-text">
              Let's find you
              <br /> the
              <span>
                <b> Best Insurance</b>
              </span>
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 10,
                marginTop: "50px",
              }}
            >
              <Typography variant="p" className="best-price best-price1">
              <img src={bestprice} alt="" />
                50+ insurers with one <br />
                of the best prices
              </Typography>
              <Typography variant="p" className="best-price best-price2">
                <img src={quickeasy} alt="" />
                Quick, easy &<br />
                hassle free

              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box>
            <TopSlider />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomePageTop;
