import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import happyimg from "../Images/happy-member.png";
import pay from "../Images/pay.png";
import download from "../Images/download.png";
import claim from "../Images/claim-icon.png";
import mail from "../Images/mail.png";
import service from "../Images/other.png";
import whatsapp from "../Images/whatsapp.png";

const HappyMembers = () => {
  return (
    <Box pt={4} pb={4}>
      <Typography pb={4} variant="h4">
        I am a happy member
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <Box sx={{ display: "flex", gap: "30px", flexWrap: "wrap" }}>
            <Paper className="happy-member" align="center" component="div" sx={{ p: 4, borderRadius: "10px" }} variant="outlined">
              <img src={pay} alt="" />
              <Typography variant="h6">Pay premium</Typography>
            </Paper>
            <Paper className="happy-member" align="center" component="div" sx={{ p: 4, borderRadius: "10px" }} variant="outlined">
              <img src={download} alt="" />
              <Typography align="center" variant="h6">
                Download statement
              </Typography>
            </Paper>
            <Paper className="happy-member" align="center" component="div" sx={{ p: 4, borderRadius: "10px" }} variant="outlined">
              <img src={claim} alt="" />
              <Typography align="center" variant="h6">
                Register claim
              </Typography>
            </Paper>
            <Paper className="happy-member" align="center" component="div" sx={{ p: 4, borderRadius: "10px" }} variant="outlined">
              <img src={mail} alt="" />
              <Typography align="center" variant="h6">
                Raise service request
              </Typography>
            </Paper>
            <Paper className="happy-member" align="center" component="div" sx={{ p: 4, borderRadius: "10px" }} variant="outlined">
              <img src={service} alt="" />
              <Typography align="center" variant="h6">
                Other services
              </Typography>
            </Paper>
            <Paper className="happy-member" align="center" component="div" sx={{ p: 4, borderRadius: "10px" }} variant="outlined">
              <img src={whatsapp} alt="" />
              <Typography align="center" variant="h6">
                Pay premium using WhatsApp
              </Typography>
            </Paper>
          </Box>
        </Grid>
        <Grid sx={{ alignSelf: 'end' }} item xs={12} md={5}>
          <Box>
            <img src={happyimg} alt="" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HappyMembers;
