import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Grid,
  Paper,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';



const Navbar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar  position="static" color="transparent">
      <Toolbar>
        {/* <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuClick}
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton> */}
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        <img src='https://www.unicodesystems.in/theme/web/core/unicode/images/logo.png' alt=''/>
        </Typography>
        <Button color="inherit">Home</Button>
        <Button color="inherit">About</Button>
        <Button color="inherit">Services</Button>
        {/* Dropdown Button */}
        <Button color="inherit" onClick={handleMenuClick}>
          Dropdown
        </Button>
        <ArrowDropDownIcon />
        {/* Dropdown Menu */}
        <Menu
          id="dropdown-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenuClose}>
          <Grid container spacing={2}>
               <Grid item xs={3}>
                 <Paper sx={{ p: 2 }}>
                   <Typography variant="subtitle1">Category 1</Typography>
                   <Button color="inherit">Link 1</Button>
                   <Button color="inherit">Link 2</Button>
                   {/* Add more links as needed */}
                 </Paper>
               </Grid>
               <Grid item xs={3}>
                 <Paper sx={{ p: 2 }}>
                   <Typography variant="subtitle1">Category 2</Typography>
                   <Button color="inherit">Link 3</Button>
                   <Button color="inherit">Link 4</Button>
                   {/* Add more links as needed */}
                 </Paper>
               </Grid>
               <Grid item xs={3}>
                 <Paper sx={{ p: 2 }}>
                   <Typography variant="subtitle1">Category 3</Typography>
                   <Button color="inherit">Link 5</Button>
                   <Button color="inherit">Link 6</Button>
                   {/* Add more links as needed */}
                 </Paper>
               </Grid>
             </Grid>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>Option 2</MenuItem>
          <MenuItem onClick={handleMenuClose}>Option 3</MenuItem>
        </Menu>
        {/* End Dropdown Menu */}
        <Button color="inherit">Contact</Button>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
