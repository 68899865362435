import React from 'react';
import { TextField, Button, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@mui/material';
import Navbar from '../components/Navbar';
const MyForm = () => {
  const [formData, setFormData] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    subscribe: false,
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    console.log('Form submitted:', formData);
  };

  return (
    <>
    <Navbar/>
    <form onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: 'auto' }}>
      <TextField
        fullWidth
        label="First Name"
        variant="outlined"
        margin="normal"
        name="firstName"
        value={formData.firstName}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        label="Last Name"
        variant="outlined"
        margin="normal"
        name="lastName"
        value={formData.lastName}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        type="email"
        label="Email"
        variant="outlined"
        margin="normal"
        name="email"
        value={formData.email}
        onChange={handleChange}
      />
      <FormControl fullWidth variant="outlined" margin="normal">
        <InputLabel>Gender</InputLabel>
        <Select
          label="Gender"
          name="gender"
          value={formData.gender}
          onChange={handleChange}
        >
          <MenuItem value="male">Male</MenuItem>
          <MenuItem value="female">Female</MenuItem>
          <MenuItem value="other">Other</MenuItem>
        </Select>
      </FormControl>
      <FormControlLabel
        control={
          <Checkbox
            checked={formData.subscribe}
            onChange={handleChange}
            name="subscribe"
            color="primary"
          />
        }
        label="Subscribe to newsletter"
      />
      <Button type="submit" variant="contained" color="primary" fullWidth>
        Submit
      </Button>
    </form>
    </>
  );
};

export default MyForm;
