import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
// import PhoneIcon from "@mui/icons-material/Phone";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const SeletCountry = () => {
  const [countryCode, setCountryCode] = React.useState("+91");
  const [phoneNumber, setPhoneNumber] = React.useState("");

  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };
  return (
    <>
      <Select value={countryCode} onChange={handleCountryCodeChange} displayEmpty inputProps={{ "aria-label": "Select Country" }}>
        <MenuItem value="" disabled>
          Select Country
        </MenuItem>
        <MenuItem value="+91">IND</MenuItem>
        <MenuItem value="+92">PAK</MenuItem>
        {/* Add more countries as needed */}
      </Select>
      <TextField
        label="Phone Number"
        fullWidth
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        InputProps={{
          startAdornment: <InputAdornment position="start">{countryCode}</InputAdornment>,
        }}
      />
    </>
  );
};

export default SeletCountry;
